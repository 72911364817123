html, body, .main-content, #root {
    height: 100%;
  }
  .stContainer {
      height:100%;
    display: flex;
    flex-direction: column;
  }
  .stContent {
    flex: 1 0 auto;
  }
  .stFooter {
    flex-shrink: 0;
  }
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rotating-spinner {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}


.lw-progress-bar {
  position:fixed;
  z-index: 10;
  top: 0;
  left:0;
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
}

.lw-progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}