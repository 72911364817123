//
// Form validation
//

// Validation

.has-success,
.has-danger {
    position: relative;

    // &:after, {
    //     width: 19px;
    //     height: 19px;
    //     line-height: 19px;
    //     text-align: center;
    //     font-family: 'NucleoIcons';
    //     display: inline-block;
    //     position: absolute;
    //     right: 15px;
    //     top: 2px;
    //     transform: translateY(50%);
    //     border-radius: 50%;
    //     font-size: 9px;
    //     opacity: 1;
    // }
}

.has-success {
    // &:after {
    //     content: "\ea26";
    //     color: daken($form-feedback-valid-color, 18%);
    //     background-color: $form-feedback-valid-bg;
    // }
    
    .form-control {
        background-color: $input-focus-bg;

        &:focus {
            border-color: $input-focus-border-color;
        }


        // Placeholder

        &::placeholder {
            color: $form-feedback-valid-color;
        }
    }
}

.has-danger {
    // &:after {
    //     content: "\ea33";
    //     color: daken($form-feedback-invalid-color, 18%);
    //     background-color: $form-feedback-invalid-bg;
    // }
    & .invalid-feedback {
        display:block;
    }

    .form-control {
        background-color: $input-focus-bg;

        &:focus {
            border-color: $input-focus-border-color;
        }

        // Placeholder

        &::placeholder {
            color: $form-feedback-invalid-color;
        }
    }
}
